import React, { useCallback } from "react";
import { Button, Skeleton } from "antd";
import { useFetchSubscriptionPlans } from "common/hooks/queries";
import { check_purple, check_purple_overlap } from "components/imagepath";

export default function SubscriptionPlan(props) {
  const { data: plans, isLoading } = useFetchSubscriptionPlans();

  const { planSelected, selectedPlan } = props;

  const isSelectedPlan = useCallback(
    (plan) => {
      return selectedPlan?.id === plan.id;
    },
    [selectedPlan]
  );

  return (
    <Skeleton loading={isLoading} active>
      <section className="bg-white p-4 sm:p-6 md:p-4 lg:p-8 pb-4 flex flex-wrap justify-between items-stretch gap-4 min-w-[320px]">
        {plans?.length > 0 &&
          plans?.map((plan) => (
            <section
              key={plan.type}
              className={`${isSelectedPlan(plan) ? "bg-secondary-dark text-white" : "bg-secondary-light text-black"} 
                w-full sm:w-[45%] md:w-[45%] lg:w-[30%] xl:w-[30%] h-auto pt-6 sm:pt-8 md:pt-6 lg:pt-10 pl-4 pr-4 sm:pl-10 sm:pr-10 md:pl-8 md:pr-8 lg:pl-14 lg:pr-14 rounded-3xl flex flex-col justify-between relative min-w-[320px]`}
              style={{ minHeight: '300px' }}
            >
              {plan?.isPopular && (
                <div className="absolute right-4 sm:right-6 md:right-4 lg:right-9 top-4 sm:top-6 md:top-4 lg:top-7 flex justify-center shadow-md items-center h-7 w-[7.5rem] bg-white text-primary-40 font-extrabold text-xs rounded-md">
                  MOST POPULAR
                </div>
              )}

              <div className="flex-grow">
                <span
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-black"} text-4xl font-bold leading-[2.875rem] pb-5 pr-0.5`}
                >
                  ${plan?.price}
                </span>
                <span className={`${isSelectedPlan(plan) ? "text-white" : "text-black"}`}>
                  / {plan?.billCycle}
                </span>
                <header
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-dark-60"} text-[1.75rem] font-medium pb-1.5`}
                >
                  <h5>{plan.name}</h5>
                </header>
                <p
                  className={`${isSelectedPlan(plan) ? "text-white" : "text-dark-60"} 
                  text-xs font-small text-gray-textColor pb-2.5`}
                  style={{ maxWidth: '400px' }}
                >
                  {plan?.description}
                </p>
                <ul className="list-none pl-0 mb-4 sm:mb-6 md:mb-4 lg:mb-8">
                  <li className="flex flex-row items-center mb-2">
                    <img
                      src={
                        isSelectedPlan(plan)
                          ? check_purple_overlap
                          : check_purple
                      }
                      className="w-5 h-5 mr-3"
                    />
                    {plan.features?.maxUsersCount} users
                  </li>
                  <li className="flex flex-row items-center mb-2">
                    <img
                      src={
                        isSelectedPlan(plan)
                          ? check_purple_overlap
                          : check_purple
                      }
                      className="w-5 h-5 mr-3"
                    />
                    {plan.features?.maxAppointmentsCount} appointments
                  </li>
                </ul>
              </div>
              <Button
                onClick={() => planSelected(plan)}
                className={`${
                  isSelectedPlan(plan)
                    ? "bg-white text-primary-dark"
                    : "bg-primary-dark text-white"
                }
                  rounded-xl h-11 w-full flex justify-center font-medium items-center mb-4 sm:mb-6 md:mb-4 lg:mb-8`}
              >
                {isSelectedPlan(plan) ? "Selected" : "Choose Plan"}
              </Button>
            </section>
          ))}
      </section>
    </Skeleton>
  );
}