import React, { useState } from "react";
import { Button, Modal, Result, Steps } from "antd";
import {
  clearPaymentSession,
  dispatchNotification,
  getCurrentTimeZone,
  isTherapistRoute,
  storePaymentSession,
} from "helper";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getUser } from "store/selectors";
import {
  createPaypalSubscription,
  createStripeSubscription,
} from "services/ApiService";
import { PaymentTypes, Route_Url, SubscriptionStatus } from "common/constants";
import SubscriptionPlan from "components/Signup/SubscriptionPlans";
import SubscriptionPayment from "components/Signup/SubscriptionPayment";
import { useMemo } from "react";
import { hasActiveSubscription, isAdminRoute, isOfficeManager, isOfficeManagerRoute } from "helper/ValueHelper";

export default function SubscriptionCheckProvider({ children }) {
  const user = useSelector(getUser);
 
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [current, setCurrent] = useState(0);
  const [paymentType, setPaymentType] = useState(PaymentTypes?.[0]);

  const title = `Subscription Cancelled`;
  const pathname = window.location.pathname;
  
  const OkButtonText = useMemo( () =>  current === 0 ? "Choose Plan" : "Pay & Subscribe", [current]);
  const therapistRoute = isTherapistRoute(pathname);
  const officeManagerRoute = isOfficeManagerRoute(pathname);
  const adminRoute = isAdminRoute(pathname);
  const subscriptionActive = hasActiveSubscription(user);
  const showBlocker = user && Object.keys(user).length > 0 && (therapistRoute || officeManagerRoute || adminRoute) && !subscriptionActive;


  const onClickSubscribe = async () => {
    setShowModal(true);
  };

  const handlePaymentSelection = (value) => {
    setPaymentType(value);
  };

  const handleSubscriptionSelect = (plan) => {
    setSelectedSubscription(plan);
  };

  const handleSubscriptionCreate = () => {
    if (current === 0) {
      if (!selectedSubscription) {
        dispatchNotification("error", "Please select a subscription plan");
        return;
      }
      setCurrent(current + 1);
    }

    if (current === 1) {
      if (!paymentType) {
        dispatchNotification("error", "Please select a payment type");
        return;
      }

      createSubcription();
    }
  };

  const createSubcription = async () => {
    setLoading(true);
    const { firstName, lastName, email, gender, mobile, address, exp, ssn, dob, education, about } = user;
    const userPayload = {
        firstName, lastName, email, dob, gender, mobile, address, exp, ssn, education, about
    }
    const payload = {
      ...userPayload,
      role: "therapist",
      timezone: getCurrentTimeZone(),
      subscription: {
        planId: selectedSubscription?.id,
      },
    };

    clearPaymentSession();
    switch (paymentType) {
      // Stripe & CashApp
      case PaymentTypes?.[0]:
      case PaymentTypes?.[4]:
        createStripePayment(payload);
        break;

      // Paypal & Venmo
      case PaymentTypes?.[1]:
      case PaymentTypes?.[2]:
        createPaypalPayment(payload);
        break;
    }
  };

  const createPaypalPayment = async (payload) => {
    try {
      const response = await createPaypalSubscription(payload);
      setLoading(false);
      storePaymentSession(response?.id);
      window.location.href = response?.links?.find(
        (link) => link.rel === "approve"
      )?.href;
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatchNotification(
        "error",
        "Error occured while creating account",
        error?.message
      );
    }
  };

  const createStripePayment = async (payload) => {
    try {
      const response = await createStripeSubscription(payload);
      setLoading(false);
      storePaymentSession(response?.sessionId);
      window.location.href = response?.sessionUrl;
    } catch (error) {
      console.log(error);
      setLoading(false);
      dispatchNotification(
        "error",
        "Error occured while creating account",
        error?.message
      );
    }
  };




  const StepDisplayComponent = {
    0: (
      <SubscriptionPlan
        planSelected={handleSubscriptionSelect}
        selectedPlan={selectedSubscription}
      />
    ),
    1: (
      <SubscriptionPayment
        paymentType={paymentType}
        selectPayment={handlePaymentSelection}
      />
    ),
  };
 
  if (showBlocker) {
    return (
      <section className="w-screen h-screen flex justify-center items-center">
        <Result
          status="info"
          title={title}
          subTitle="Subcription has been cancelled. Please create a new subscription to continue."
          extra={[
            <Button
              onClick={onClickSubscribe}
              loading={loading}
              className="bg-primary-dark text-white h-12"
              key="redirect"
            >
              Subscribe
            </Button>,
          ]}
        />
        <Modal
          open={showModal}
          style={{
            minWidth: "90vw",
          }}
          title="Renew Subscription"
          centered
          okType="primary"
          styles={{
            content: {
              minWidth: "90vw",
            },
          }}
          confirmLoading={loading}
          okText={OkButtonText}
          cancelText="Cancel"
          onOk={handleSubscriptionCreate}
          onCancel={() => setShowModal(false)}
          maskClosable={false}
        >
          <Steps current={current} onChange={handleSubscriptionCreate} size="default" className="my-5">
            <Steps.Step title="Select Plan" />
            <Steps.Step title="Choose Payment" />
          </Steps>

          {StepDisplayComponent[current]}
        </Modal>
      </section>
    );
  }
  return <>{children}</>;
}

SubscriptionCheckProvider.propTypes = {
  children: PropTypes.node,
};
