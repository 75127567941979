import { Schedule } from "@mui/icons-material";
import { cashapp, paypal, stripe, venmo, zelle } from "components/imagepath";

const Default_Duration_In_Minutes = 60;


const DEFAULT_DISABLED_HOURS = [
  18,
  19,
  20,
  21,
  22,
  23,
  24,
  0,
  ,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
];

const Languages = {
  EN: "en",
  ES: "es",
};

const Plan_Types = {
  "starter": "Starter",
  "professional": "Professional",
  "enterprise": "Enterprise",
}

const PaymentTypes = ["Stripe", "PayPal","Venmo", "CashApp"];

const PaymentIcons = {
  Stripe: stripe,
  Venmo: venmo,
  CashApp: cashapp,
  PayPal: paypal,
};

const SignUpTabs = {
  SignUp: 0,
  Subscription: 1,
  Payment: 2
}

const Roles = {
  Therapist: "therapist",
  Client: "client",
  OfficeManager: "officeManager",
  Admin: "admin",
};

const TAB_KEYS = {
  assessmentInfo: "assessmentInfo",
  initialEvaluation: "initialEvaluation",
  treatment: "treatment",
  reports: "reports",
  evidenceSubmission: "evidenceSubmission",
  followupReport: "followupReport",
  followupEvaluation: "followupEvaluation",
};

const SETTINGS_TAB_KEYS = {
  profile: "personalInfo",
  appointmentSettings: "appointmentSettings",
  payment: "payment",
  questions: "questions",
  OfficeManager: "OfficeManager",
  Admin: "admin",
};

const QueryParamsString = {
  therapistId: "therapistId",
  clientId: "clientId",
  userId: "userId", 
  appointmentId: "appointmentId",
  followUp: 'followUp',
  reschedule: "reschedule",
  success: "success",
  error: "error",
  reportRequestId: "reportRequestId",
  cancelRescheduleAppointment: "cancelRescheduleAppointment",
}

const PaymentStatuses = {
  Success: "success",
  Error: "error",
  Process: "process",
  Pending: "pending",
  RequiresPayment: "requires_payment_method"
}

const DATE_VALIDATION_TYPES = {
  Greater: "greater",
  Lesser: "lesser"
}

const ReportTypes = {
  initialEvaluation: "initial_evaluation_report",
  followupEvaluation: "follow_up_evaluation_report",
  followupReport: "followup_report",
  evidenceSubmission: "evidence_submission_report",
};


const AppointmentStatus = {
  Cancelled: "canceled",
  Completed: "completed",
  Scheduled: "scheduled",
}

const SubscriptionStatus = {
  active: "active",
  cancelled: "canceled",
  pending: "pending",
  suspended: "suspended",
}

const FileExtensions = {
  CSV: "csv",
  PDF: "pdf",
  XLSX: "xlsx",
}


const AppointmentQueries = {
  PendingPrequisities: "&initialAssessmentSubmitted=false",
  PendingReviewReport: "fullyPaid=true&followUpEvaluationCompleted=true&followUpReportMailSent=false",
  DelayedPayments: "fullyPaid=false&treatmentPlanSubmitted=true",
  ReportRequests: "reportRequestOpen=true",
}

const AssessmentTags = {
  undefined: (
    <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Pending
    </div>
  ),
  false: (
    <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Pending
    </div>
  ),
  true: (
    <div className="w-[5.3125rem] text-sm font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
      Completed
    </div>
  ),
};

const PaidTags = (paid, totalPaid) => {
  if (paid) {
    return (
      <div className="w-[5.3125rem] font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
        Paid - ${totalPaid}
      </div>
    );
  } else {
    return (
      <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
        Not Paid
      </div>
    );
  }
};

const AppTypeTags = (followUp) => {
  if (followUp) {
    return (
      <div className="w-[5.3125rem] font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
        Follow-Up
      </div>
    );
  } else {
    return (
      <div className="w-[5.3125rem] text-sm font-medium flex items-center justify-center h-7 rounded-md">
        Initial
      </div>
    );
  }
};

const RescheduleTags = {
  onTime: (
    <div className="w-[5.3125rem] text-sm font-medium text-secondary-dark flex items-center justify-center h-7 rounded-md bg-secondary-lighter">
      On-Time
    </div>
  ),
  rescheduled: (
    <div className="w-[6.325rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Rescheduled
    </div>
  ),
  canceled: (
    <div className="w-[5.3125rem] text-sm font-medium text-primary-dark flex items-center justify-center h-7 rounded-md bg-tertiary-light">
      Canceled
    </div>
  ),
};

const getScheduleTag = (record) => {
  if (record.status === "canceled") {
    return RescheduleTags.canceled;
  }
  if (record.allowReschedule) {
    return RescheduleTags.rescheduled;
  }
  return RescheduleTags.onTime;
};

export {
  Default_Duration_In_Minutes,
  DEFAULT_DISABLED_HOURS,
  Languages,
  Plan_Types,
  PaymentTypes,
  PaymentIcons,
  SignUpTabs,
  Roles,
  TAB_KEYS,
  QueryParamsString,
  PaymentStatuses,
  SETTINGS_TAB_KEYS,
  DATE_VALIDATION_TYPES,
  ReportTypes,
  AppointmentStatus,
  FileExtensions,
  AppointmentQueries,
  SubscriptionStatus,
  AssessmentTags,
  PaidTags,
  AppTypeTags,
  RescheduleTags,
  getScheduleTag,
};
