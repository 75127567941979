import React, { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AppointmentQueries, Route_Url } from "common/constants";

const Admin_Dashboard = lazy(() => import("components/Dashboard/Admin_Dashboard/Admin_Dashboard"));
const AppoinmentList = lazy(() => import("components/appoinments/appointmentlist/AppoinmentList"));
const AppointmentBookingComplete = lazy(() => import("components/appoinments/appointmentResult"));
const CancelAppointmentBooking = lazy(() => import("components/appoinments/cancelAppointment"));
const EditAppoinments = lazy(() => import("components/appoinments/EditAppointment/EditAppoinments"));
const EvidenceSubmissionTab = lazy(() => import("components/appoinments/EditAppointment/Tabs/evidenceSubmission"));
const PaymentPage = lazy(() => import("components/payment/index"));
const CompletePage = lazy(() => import("components/payment/CompletePage"));
const PaymentOnboarding = lazy(() => import("components/payment/Onboarding"));
const PaypalPaymentPage = lazy(() => import("components/payment/paypal"));
const Client_Dashboard = lazy(() => import("components/Dashboard/Client_Dashboard"));
const Doctor_Dashboard = lazy(() => import("components/Dashboard/Doctor_Dashboard/Doctor_Dashboard"));
const DynamicAppointmentList = lazy(() => import("components/officeManager/dynamicAppointmentList"));
const RouteListenerLayout = lazy(() => import("components/auth/RouteWrapper"));
const RoleRedirect = lazy(() => import("components/RoleRedirect"));
const SignUpPage = lazy(() => import("components/Signup/index"));
const Setting = lazy(() => import("components/settings/therapist/Setting"));
const OfficeManagerSetting = lazy(() => import("components/settings/officeManager/Setting"));
const FeedbackSurvey = lazy(() => import("components/survey/FeedbackSurvey"));
const ReportRequestPage = lazy(() => import("components/report/ClientReportGeneration/ClientReport"));
const ReportRequestComplete = lazy(() => import("components/report/ClientReportGeneration/ReportRequestComplete"));
const CancelAppointmentBookingComplete = lazy(() => import("components/appoinments/cancelAppointment/CancelComplete"));
const FeedbackSurveyComplete = lazy(() => import("components/survey/FeedbackSurveyComplete"));
const UserList = lazy(() => import("components/users/UserList"));

import { RouteHistory } from "helper";
import { verifyUser } from "services/ApiService";

// Lazy Loaded Components
const Error = lazy(() => import("./components/pages/login/Error"));
const Questionnaire = lazy(() => import("./components/Questionnaire"));
const Login = lazy(() => import("./components/Login"));
const PrivateRoutes = lazy(() => import("components/auth/PrivateRoute"));
const LinkSent = lazy(() => import("components/pages/login/LinkSent"));
const GoogleLoginCallback = lazy(
  () => import("components/pages/login/GoogleLoginCallback")
);
const OutlookLoginCallback = lazy(
  () => import("components/pages/login/OutlookLoginCallback")
);

const AddAppoinments = lazy(
  () => import("components/appoinments/AddAppoinments")
);

const getDateBasedOnFlag = (isCurrentDate) => {
  const currentDate = new Date();
  if (isCurrentDate) {
    return currentDate.toISOString();
  } else {
    const oneMonthBefore = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    return oneMonthBefore.toISOString();
  }
};

const Approuter = () => {
  // eslint-disable-next-line no-unused-vars
  // const config = "/react/template"
  return (
    <>
      <BrowserRouter history={RouteHistory}>
        <Routes>
          <Route element={<RouteListenerLayout />}>
            <Route index path="/" element={<RoleRedirect />} />
            <Route
              path={Route_Url.Login}
              element={<Login />}
              action={async () => {
                const user = await verifyUser();
                console.log(user);

                return user;
              }}
            />
            <Route path={Route_Url.SignUp} element={<SignUpPage />} />
            <Route path={Route_Url.payment} element={<PaymentPage />} />
            <Route
              path={Route_Url.paypalPayment}
              element={<PaypalPaymentPage />}
            />
            <Route path={Route_Url.Complete} element={<CompletePage />} />
            <Route
              path={Route_Url.PaymentOnboarding}
              element={<PaymentOnboarding />}
            />
            <Route
              path={`${Route_Url.AppointmentBooking}/:therapistId`}
              element={<AddAppoinments />}
            />
            <Route
              path={`${Route_Url.RequestReport}/:therapistId`}
              element={<ReportRequestPage />}
            />
            <Route
              path={`${Route_Url.CancelAppointmentBooking}`}
              element={<CancelAppointmentBooking />}
            />

            <Route path={Route_Url.Questionnaire} element={<Questionnaire />} />
            <Route
              path={Route_Url.AppointmentBookingComplete}
              element={<AppointmentBookingComplete />}
            />
            <Route
              path={Route_Url.EvidenceSubmission}
              element={<EvidenceSubmissionTab isPage />}
            />

            {/* Ptivate Route */}
            <Route element={<PrivateRoutes />}>
              <Route
                path={Route_Url.PatientDashboard}
                element={<Client_Dashboard />}
              />
              <Route
                path={Route_Url.AdminDashboard}
                element={<Admin_Dashboard />}
              />
              <Route
                path={Route_Url.OfficeManager.PendingPrequisities}
                element={<DynamicAppointmentList title="Pending Pre-Requisities" query={`${AppointmentQueries.PendingPrequisities}&gt=${getDateBasedOnFlag(true)}`}/>}
              />
              <Route
                path={Route_Url.OfficeManager.PendingReviewReport}
                element={<DynamicAppointmentList title="Reports Pending Review" query={`${AppointmentQueries.PendingReviewReport}&gt=${getDateBasedOnFlag(false)}`} />}
              />
              <Route
                path={Route_Url.OfficeManager.DelayedPayments}
                element={<DynamicAppointmentList title="Delayed Payments" query={`${AppointmentQueries.DelayedPayments}&gt=${getDateBasedOnFlag(false)}`} />}
              />
              <Route
                path={Route_Url.OfficeManager.ReportRequests}
                element={<DynamicAppointmentList title="Report Requests" query={`${AppointmentQueries.ReportRequests}`} />}
              />

              <Route
                path={Route_Url.DoctorDashboard}
                element={<Doctor_Dashboard />}
              />

              <Route
                path={Route_Url.DoctorDashboard}
                element={<Doctor_Dashboard />}
              />

              <Route
                path={Route_Url.AppointmentList}
                element={<AppoinmentList />}
              />
              <Route
                path={Route_Url.EditAppointment}
                element={<EditAppoinments />}
              />
              <Route path={Route_Url.TherapistSettings} element={<Setting />} />
              <Route path={Route_Url.OfficeManager.Settings} element={<OfficeManagerSetting />} />
              <Route path={Route_Url.Users} element={<UserList />} />
            </Route>
            <Route path={Route_Url.Survey} element={<FeedbackSurvey />} />
          </Route>

          <Route path={Route_Url.EmailVerificaiton} element={<LinkSent />} />

          <Route
            path={Route_Url.GoogleLoginCallback}
            element={<GoogleLoginCallback />}
          />
          <Route
            path={Route_Url.OutlookLoginCallback}
            element={<OutlookLoginCallback />}
          />
            <Route
              path={Route_Url.ReportRequestComplete}
              element={<ReportRequestComplete/>}
            />
            <Route
              path={Route_Url.CancelAppointmentBookingComplete}
              element={<CancelAppointmentBookingComplete/>}
            />
            <Route
              path={Route_Url.FeedbackSurveyComplete}
              element={<FeedbackSurveyComplete/>}
            />
          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
      <div className="sidebar-overlay"></div>
    </>
  );
};

export default Approuter;
