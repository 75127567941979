export const COLORS = {
  PRIMARY: {
    dark: "#8F194A",
    light: "#D982B5",
  },
  SECONDARY: {
    light: "#9AAC62",
    dark: "#414934",
  },
};
